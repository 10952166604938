.navbar {
	position: static;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.nav-menu {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 13px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Libre Baskerville', sans-serif;
	color: #fff;
}

.container {
	position: fixed;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
}

.brand {
	position: relative;
	float: left;
	text-decoration: none;
	color: #333333;
	width: 175px;
	height: 175px;
	margin-top: 0px;
	background-image: url('/20170713122722/assets/images/Barrier-Balm-Logo.png');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
}

.brand.mobilebrand {
	display: none;
}

.body {
	background-image: url('/20170713122722/assets/images/BG-Texture.jpg');
	background-position: 0px 0px;
}

.menubackgroundbar {
	position: fixed;
	left: 0%;
	top: 81px;
	right: 0%;
	bottom: auto;
	z-index: 998;
	width: 100%;
	height: 40px;
	background-color: #008083;
	box-shadow: 0 1px 3px 0 #303030;
}

.nav-link {
	padding-top: 10px;
	padding-bottom: 10px;
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: #fff;
	font-size: 13px;
	text-transform: uppercase;
}

.nav-link:hover {
	color: #fff;
	border-top: 1px none red;
	background-color: rgba(0, 0, 0, 0.1);
	box-shadow: 0 -2px 0 0 #ce5b29;
}

.headerbackground {
	position: fixed;
	z-index: 900;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 90px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-image: url('/20170713122722/assets/images/Lt-Blue-Texture.jpg');
	background-position: 0px 0px;
	background-size: auto;
}

.cornerdeco {
	position: static;
	width: 60px;
	height: 60px;
	margin-top: 10px;
	margin-left: 10px;
	background-image: url('/20170713122722/assets/images/Corner-Decoration.svg');
	background-position: 0px 0px;
	background-repeat: no-repeat;
}

.cornerdeco.cornerright {
	margin-right: 10px;
	margin-left: 0px;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.cornerdeco.cornerright.bottomright {
	margin-top: 0px;
	margin-bottom: 10px;
	-webkit-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.cornerdeco.bottomcorner {
	margin-top: 0px;
	margin-bottom: 10px;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.slide {
	width: 100%;
	height: 100%;
}

.slide.motherbaby {
	margin-top: 0px;
}

.mask {
	width: 100%;
	height: 100%;
}

.slider {
	width: 100%;
	height: 100%;
	background-color: transparent;
}

.div-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.slideshowsection {
	position: static;
	z-index: 1;
	width: 100%;
	margin-top: 9rem;
}

.div-block-2 {
	width: auto;
	margin-right: 20px;
}

.div-block-2.bikerider {
	width: auto;
	height: auto;
	margin-right: 0px;
}

.div-block-3 {
	width: 30%;
}

.slide-nav {
	display: none;
}

.left-arrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.right-arrow {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.vintagearrow {
	position: absolute;
	right: 10px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70px;
	height: 40px;
}

.vintagearrow.previousarrow {
	left: 10px;
	right: 0px;
	margin-right: 0px;
	margin-left: 0px;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.multitudeofuses {
	position: relative;
	z-index: 2;
	margin-top: 0rem;
	background-image: url('/20170713122722/assets/images/Lt-Blue-Texture.jpg');
	background-position: 0px 0px;
	background-size: auto;
	box-shadow: 0 1px 3px 0 #303030;
}

.sectiondecoration {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 10px;
	padding-left: 10px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.dottedline {
	width: 43%;
	height: 3px;
	background-image: url('/20170713122722/assets/images/Dots2.svg');
	background-position: 50% 0%;
	background-repeat: repeat-x;
}

.centerfluer {
	width: 14%;
	height: 75px;
	background-image: url('/20170713122722/assets/images/Top-Decoration.svg');
	background-position: 50% 66%;
	background-size: auto;
	background-repeat: no-repeat;
}

.centerfluer.bottomfluer {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.sectionheader {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.heading {
	margin-top: 0px;
	margin-bottom: 0px;
	font-family: Poppins, sans-serif;
	color: #47413c;
	font-size: 30px;
	font-weight: 800;
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.headerdecoration {
	width: 30px;
	height: 30px;
	margin-right: 0.5rem;
	background-image: url('/20170713122722/assets/images/Header-Decoration.svg');
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.headerdecoration.rightheader {
	margin-right: 0rem;
	margin-left: 0.5rem;
	-webkit-transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
	transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.dottedunderline {
	width: 225px;
	height: 3px;
	margin-bottom: 20px;
	background-image: url('/20170713122722/assets/images/Dots2.svg');
	background-position: 50% 0%;
	background-size: auto;
	background-repeat: repeat-x;
}

.dottedunderline.whitedots {
	width: 222px;
	background-image: url('/20170713122722/assets/images/Dots-White.svg');
}

.dottedunderline.blurbdots {
	width: 225px;
	margin-bottom: 5px;
}

.dottedunderlineholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.usesblockholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 2rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.usesblock {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33%;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.usestitleholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: baseline;
	-webkit-align-items: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
}

.usestitleholder.singlelinetitles {
	-webkit-box-align: baseline;
	-webkit-align-items: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
}

.titlestar {
	width: 15px;
	height: 15px;
	margin-top: 0px;
	margin-right: 10px;
	padding-top: 0px;
	background-image: url('/20170713122722/assets/images/Star-White.svg');
	background-position: 50% 50%;
	background-size: 15px 15px;
	background-repeat: no-repeat;
}

.titlestar.rightstar {
	margin-right: 0px;
	margin-left: 10px;
}

.titlestar.brownstar {
	background-image: url('/20170713122722/assets/images/Star-Brown.svg');
}

.heading-2 {
	margin-bottom: 0px;
	font-family: 'Libre Baskerville', sans-serif;
	color: #fff;
	font-size: 16px;
	text-align: center;
	letter-spacing: 1px;
}

.heading-2.brownheading {
	margin-top: 0px;
	color: #47413c;
	text-transform: uppercase;
}

.usesimageholder {
	width: 200px;
	height: 200px;
}

.usesimageholder.athletics {
	padding-top: 37px;
}

.usesimageholder.nursing {
	padding-top: 63px;
}

.usesimageholder.creamjar {
	width: 300px;
	height: 300px;
	display: flex;
}

.usesimageholder.creamjar img {
	align-self: center;
}

.threeformulas {
	margin-bottom: 3.5rem;
	padding-top: 33px;
}

.downloadprescription {
	background-image: url('/20170713122722/assets/images/Salmon-Texture.jpg');
	background-position: 0px 0px;
	box-shadow: 0 1px 3px 0 #303030;
}

.topcornerdeco {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.downloadgraphic {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.downloadholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.image {
	margin-bottom: 7px;
}

.image-2 {
	margin-top: 5px;
}

.distressedbuttonlarge {
	width: 100%;
	margin-top: 20px;
	background-color: transparent;
	background-image: url('/20170713122722/assets/images/Button-BG.svg');
	background-position: 50% 50%;
	background-size: 200px;
	background-repeat: no-repeat;
	font-family: Poppins, sans-serif;
	font-size: 20px;
	font-weight: 800;
	text-align: center;
	letter-spacing: 3px;
	text-transform: uppercase;
}

.distressedbuttonlarge:hover {
	text-decoration: underline;
}

.distressedbuttonlarge.learnmorebrown {
	margin-top: 0px;
	font-size: 16px;
}

.distressedbuttonlarge.learnmorebrown:hover {
	font-style: normal;
	text-decoration: underline;
}

.distressedbuttonlarge.primarybutton {
	width: auto;
	margin-top: 0px;
	background-size: cover;
	text-align: center;
	letter-spacing: 1px;
}

.distressedbuttonlarge.secondarybutton {
	width: auto;
	background-color: #008083;
	background-image: none;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 1px;
}

.distressedbuttonlarge.secondarybutton:hover {
	background-color: #ce5b29;
}

.blurbtopper {
	width: 150px;
	height: 50px;
	min-height: 0px;
	margin-bottom: 5px;
	background-image: url('/20170713122722/assets/images/Blurb-Topper.svg');
	background-position: 50% 100%;
	background-size: auto;
	background-repeat: no-repeat;
}

.successblurb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: 4rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: left;
}

.storyblurb {
	width: auto;
	margin-bottom: 0.5rem;
	font-family: Poppins, sans-serif;
	font-weight: 400;
	text-align: center;
}

.text-block {
	font-family: Poppins, sans-serif;
	color: #47413c;
	font-weight: 700;
}

.text-block-2 {
	margin-top: 5px;
	margin-bottom: 5px;
	color: #47413c;
	font-size: 18px;
	line-height: 22px;
}

.grip-logo-link {
	float: right;
	opacity: 0.5;
	-webkit-transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease, opacity 200ms ease;
	transition: background-color 200ms ease, color 200ms ease, border-color 200ms ease, opacity 200ms ease;
}

.grip-logo-link:hover {
	opacity: 1;
}

.footer-list-link {
	margin-bottom: 0.35rem;
	padding-right: 0.25rem;
	padding-bottom: 0rem;
	padding-left: 0.35rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px none hsla(0, 0%, 54.5%, 0.25);
	border-left: 3px solid transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #47413c;
	line-height: 1.3rem;
	text-align: center;
	text-decoration: none;
	display: inline-block;
}

.footer-list-link:hover {
	border-bottom-color: transparent;
	border-left-color: transparent;
	color: #ce5b29;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.footer-links-div {
	padding: 3rem 2rem 2rem;
	background-color: #333;
	background-image: url('/20170713122722/assets/images/Lt-Blue-Texture.jpg');
	background-position: 0px 0px;
	background-repeat: repeat;
	box-shadow: 0 1px 3px 0 #303030;
	font-family: Poppins, sans-serif;
}

.list-link-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.link-list-title {
	margin-bottom: 0.5rem;
	font-family: 'Libre Baskerville', sans-serif;
	color: #47413c;
	font-size: 18px;
	font-weight: 500;
	text-align: center;
}

.phone-number {
	display: none;
	color: #dbdbdb;
	text-decoration: none;
}

.footer-signoff-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	font-family: Poppins, sans-serif;
	color: #47413c;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-decoration: none;
}

.footer-signoff-link:hover {
	color: #ce5b29;
}

.list-link-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: -0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-copyright {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	font-family: Poppins, sans-serif;
	color: #47413c;
	font-size: 0.9rem;
}

.column-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.link-list-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 85%;
	min-width: 925px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.grip-logo-image {
	width: 9rem;
	min-width: 150px;
	margin-top: 5px;
	margin-left: 0px;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.web-development-by {
	position: absolute;
	left: auto;
	top: 0%;
	right: 0%;
	bottom: auto;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #47413c;
	font-size: 0.65rem;
	line-height: 0.75rem;
	letter-spacing: 0.045rem;
}

.footer-section {
	display: block;
}

.sign-off-div-wrap {
	width: 85%;
	min-width: 991px;
	padding-right: 2rem;
	padding-left: 2rem;
}

.signoff-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
	padding-top: 1.5rem;
	padding-bottom: 2.5rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: transparent;
	background-image: url('/20170713122722/assets/images/BG-Texture.jpg');
	background-position: 0px 0px;
}

.column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.text-block-3 {
	color: #47413c;
	text-align: center;
}

.footer-signoff-links {
	display: inline-block;
	margin-right: 1rem;
	border-bottom: 2px solid rgba(78, 77, 93, 0.5);
	line-height: 1.25rem;
}

.footer-signoff-links:hover {
	border-bottom-color: transparent;
}

.followus {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	/* -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; */
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url("../images/Salmon-Texture.jpg");
	background-position: 50% 50%;
	background-size: auto;
}

.followusholder {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	width: 33.3%;
}

.mc-field-group {
	text-align: center;
}

.socialicons {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 10px;
	margin-bottom: 2rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.socialicon {
	margin-right: 10px;
	margin-left: 10px;
	cursor: pointer;
}

.makinghineyshappy {
	width: 33%;
}

.link-wrap {
	margin-bottom: 1rem;
	font-family: Poppins, sans-serif;
	color: #005590;
}

.left-navigation {
	display: inline-block;
	width: 21%;
	margin-top: 2rem;
	margin-left: 0rem;
	padding-bottom: 0rem;
	float: none;
	border-top: 5px none #ed1c29;
	background-color: rgba(0, 0, 0, 0.05);
}

.table-styling {
	margin-bottom: 1rem;
}

.list-item {
	margin-bottom: 0.35rem;
	font-family: Poppins, sans-serif;
}

.left-nav-link-level-1b {
	display: block;
	width: 100%;
	padding: 1rem 0.5rem 1rem 2.25rem;
	border-bottom: 1px none #aaa;
	background-image: url('/20170713122722/assets/images/Dots2.svg');
	background-position: 0% 100%;
	background-size: 20px;
	background-repeat: repeat-x;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Libre Baskerville', sans-serif;
	color: #47413c;
	font-size: 12px;
	text-decoration: none;
}

.left-nav-link-level-1b:hover {
	background-color: transparent;
	color: #ce5b29;
}

.text-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px none #61bb46;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #008083;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #ce5b29;
}

.paragraph-5 {
	font-family: Poppins, sans-serif;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.45rem;
	font-family: Poppins, sans-serif;
	color: #747474;
	font-size: 0.65rem;
}

.block-quote-2 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left-color: #ce5b29;
	font-family: 'Libre Baskerville', sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

.breadcrumbs-list-item {
	display: inline-block;
}

.left-nav-link-level-1 {
	display: block;
	width: 100%;
	padding: 1rem 0.5rem;
	border-bottom: 1px none #aaa;
	background-image: url('/20170713122722/assets/images/Dots2.svg');
	background-position: 0% 100%;
	background-size: 20px;
	background-repeat: repeat-x;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Libre Baskerville', sans-serif;
	color: #47413c;
	font-weight: 700;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link-level-1:hover {
	background-color: transparent;
	color: #ce5b29;
}

.nested-unordered-list {
	margin-bottom: 0px;
}

.breadcrumb-link {
	display: inline-block;
	margin-right: 0.45rem;
	border-bottom: 1px dotted #7a7a7a;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #ce5b29;
	font-size: 0.65rem;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #008083;
}

.breadcrumb-link.w--current {
	border-bottom-color: transparent;
	color: #333;
}

.container-2 {
	position: relative;
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.container-2.inside-page-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0rem;
	background-color: transparent;
	opacity: 1;
}

.intro-paragraph {
	font-family: 'Libre Baskerville', sans-serif;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.breadcrumbs-list {
	margin-bottom: 1rem;
	padding-left: 0px;
}

.h2-link {
	display: inline;
	margin-bottom: 1rem;
	border-bottom: 1px none #a8a8a8;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #008083;
	font-size: 2rem;
	line-height: 2.5rem;
	font-weight: 700;
}

.h2-link:hover {
	border-bottom-color: transparent;
	color: #ce5b29;
}

.left-nav-list-item {
	margin-right: 0rem;
	margin-left: 0rem;
}

.left-nav-list-item.no-right-margin {
	margin-right: 0rem;
	margin-left: 0rem;
}

.left-nav-list-item.half-margin-width {
	margin-right: 0rem;
	margin-left: 0rem;
	background-color: #ce5b29;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	color: #fff;
}

.breadcrumbs {
	margin-bottom: 1.5rem;
}

.leftnavlinkblock {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.left-nav-list {
	padding-top: 8px;
	padding-right: 8px;
	padding-left: 8px;
}

.left-nav-section-title-link {
	display: block;
	padding: 0.5rem 1rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #fff;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 700;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-section-title-link:hover {
	background-color: transparent;
}

.left-nav-link-level-1a {
	display: block;
	width: 100%;
	padding: 1rem 0.5rem 1rem 1.5rem;
	border-bottom: 1px none #aaa;
	background-image: url('/20170713122722/assets/images/Dots2.svg');
	background-position: 0% 100%;
	background-size: 20px;
	background-repeat: repeat-x;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Libre Baskerville', sans-serif;
	color: #47413c;
	font-weight: 700;
	text-decoration: none;
}

.left-nav-link-level-1a:hover {
	background-color: transparent;
	color: #ce5b29;
}

.heading-6 {
	font-family: Poppins, sans-serif;
	color: #008083;
	font-weight: 700;
}

.left-nav-red-diamond {
	width: 10px;
	height: 10px;
	margin-bottom: 0.85rem;
	opacity: 0;
}

.ordered-list {
	margin-bottom: 1rem;
}

.main-content-wrap {
	width: 100%;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
}

.main-content-wrap.with-left-sidebar {
	width: 79%;
	padding: 2rem 1.5rem;
	float: right;
}

.main-content-wrap.between-left-right {
	width: 58%;
	padding-top: 2rem;
}

.inside-page-section {
	margin-top: 11rem;
}

.right-nav-col {
	width: 21%;
	margin-top: 0px;
	padding-left: 1rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: transparent;
}

.right-nav-div {
	margin-top: 0px;
	padding-top: 4rem;
	padding-right: 10px;
	padding-bottom: 10px;
	border-right: 6px none #edb761;
}

.sidebar-section-title {
	margin-top: 1rem;
	margin-bottom: 0rem;
	padding-left: 0px;
	background-color: transparent;
	font-family: 'Libre Baskerville', sans-serif;
	color: #ce5b29;
	font-size: 1.25rem;
	line-height: 1.775rem;
	font-weight: 600;
	text-transform: none;
}

.right-nav-a {
	position: relative;
	display: block;
	margin-right: 20px;
	margin-left: 0rem;
	padding: 12px 2px;
	border-bottom: 1px none #47413c;
	background-image: url('/20170713122722/assets/images/Dots2.svg');
	background-position: 0% 100%;
	background-size: 20px;
	background-repeat: repeat-x;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #47413c;
	font-size: 0.9em;
	text-decoration: none;
}

.right-nav-a:hover {
	color: #ce5b29;
}

.right-nav-a.w--current {
	border-bottom-color: #edb761;
}

.paragraph-2 {
	margin-top: 10px;
	margin-left: 0px;
	font-family: Poppins, sans-serif;
	font-size: 0.9em;
}

.responsive-iframe {
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
}

.responsive-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

@media (max-width: 1150px) {
	.nav-link {
		padding: 10px 10px;
	}
}

@media (max-width: 991px) {
	.navbar {
		position: relative;
	}

	.nav-menu {
		position: fixed;
		left: auto;
		top: 0%;
		right: auto;
		bottom: 0%;
		z-index: 999;
		margin-top: 122px;
		background-color: #ce5b29;
	}

	.container {
		position: fixed;
		z-index: 999;
	}

	.brand {
		display: none;
	}

	.brand.mobilebrand {
		position: fixed;
		z-index: 999;
		display: block;
		width: 150px;
		height: 150px;
		margin-top: 0px;
	}

	.nav-link {
		margin-right: 10px;
		margin-left: 10px;
		background-image: url('/20170713122722/assets/images/Dots-White.svg');
		background-position: 0% 100%;
		background-size: auto 2px;
		background-repeat: repeat-x;
	}

	.menu-button {
		position: fixed;
		left: auto;
		top: 64px;
		right: 0%;
		bottom: auto;
		z-index: 999;
		color: #fff;
		font-size: 40px;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.slide {
		margin-top: 73px;
		background-image: none;
	}

	.mask {
		height: auto;
	}

	.slider {
		height: auto;
	}

	.slideshowsection {
		position: static;
		width: 100%;
		margin-top: 9rem;
	}

	.div-block-2 {
		width: 50%;
	}

	.div-block-2.bikerider {
		width: 50%;
	}

	.left-arrow {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.right-arrow {
		position: absolute;
		z-index: 200;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.multitudeofuses {
		position: relative;
		z-index: 1;
		width: 100%;
		margin-top: 2rem;
	}

	.dottedline {
		width: 35%;
	}

	.centerfluer {
		width: 30%;
	}

	.usesblock {
		width: 28%;
	}

	.usestitleholder {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.titlestar.brownstar {
		width: 25px;
		height: 25px;
	}

	.heading-2.brownheading {
		margin-bottom: 5px;
		line-height: 24px;
	}

	.usesimageholder.creamjar {
		width: 200px;
		height: 200px;
	}

	.downloadgraphic {
		width: 55%;
	}

	.successblurb {
		margin-right: 2rem;
	}

	.grip-logo-link {
		margin-right: 0rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
		float: right;
	}

	.footer-list-link {
		text-align: center;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-bottom: 1.5rem;
		float: left;
	}

	.list-link-div {
		width: 33.33%;
		margin-bottom: 1rem;
	}

	.footer-copyright {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		float: left;
	}

	.column-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.link-list-wrapper {
		width: 100%;
		min-width: auto;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.sign-off-div-wrap {
		width: 100%;
		min-width: auto;
	}

	.column-2 {
		padding-right: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.footer-signoff-links {
		margin-right: 0.75rem;
		margin-left: 0rem;
	}

	.makinghineyshappy {
		width: 60%;
	}

	.container-2 {
		width: 100%;
	}

	.main-content-wrap {
		padding-top: 2rem;
	}

	.main-content-wrap.with-left-sidebar {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.main-content-wrap.between-left-right {
		width: 100%;
	}

	.inside-page-section {
		margin-top: 8rem;
	}

	.right-nav-col {
		display: none;
	}

	.followusholder {
		width: 50%;
	}
}

@media (max-width: 767px) {
	.container {
		position: fixed;
	}

	.brand.mobilebrand {
		margin-top: 0px;
		left: 20px;
	}

	.slide {
		margin-top: 0px;
		background-image: none;
	}

	.mask {
		height: auto;
	}

	.slider {
		height: auto;
	}

	.slideshowsection {
		margin-top: 9rem;
	}

	.div-block-2 {
		width: 40%;
	}

	.div-block-2.bikerider {
		width: 40%;
	}

	.div-block-3 {
		width: 50%;
	}

	.slide-nav {
		display: none;
	}

	.multitudeofuses {
		z-index: 1;
		margin-top: 1rem;
	}

	.centerfluer {
		background-position: 50% 60%;
	}

	.dottedunderline {
		margin-bottom: 10px;
	}

	.dottedunderline.whitedots {
		margin-bottom: 10px;
	}

	.dottedunderlineholder {
		margin-bottom: 0px;
	}

	.usesblockholder {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.usesblock {
		width: 100%;
		margin-bottom: 20px;
	}

	.usesimageholder.athletics {
		height: auto;
		padding-top: 6px;
	}

	.usesimageholder.nursing {
		height: 110px;
		padding-top: 0px;
	}

	.usesimageholder.creamjar {
		width: 300px;
		height: 300px;
	}

	.downloadgraphic {
		width: 75%;
	}

	.distressedbuttonlarge.learnmorebrown {
		margin-bottom: 1rem;
	}

	.grip-logo-link {
		margin-bottom: 0rem;
		float: none;
	}

	.footer-list-link {
		text-align: center;
	}

	.footer-signoff-link-wrap {
		display: inline-block;
		float: none;
	}

	.footer-links-div {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.list-link-div {
		width: 50%;
	}

	.footer-copyright {
		float: none;
	}

	.column-3 {
		overflow: hidden;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.link-list-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.web-development-by {
		right: 34%;
	}

	.column-2 {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.text-block-3 {
		margin-bottom: 20px;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.main-content-wrap.with-left-sidebar {
		padding-top: 1rem;
	}

	.right-nav-col {
		display: none;
	}

	.followus {
		flex-wrap: wrap;
	}
}

@media (max-width: 479px) {
	.nav-menu {
		position: fixed;
		margin-top: 122px;
	}

	.container {
		z-index: 999;
	}

	.nav-link {
		display: block;
	}

	.nav-link.firstmobile {
		margin-top: 23px;
	}

	.slide {
		margin-top: 0px;
		background-image: none;
	}

	.slide.motherbaby {
		margin-top: 0px;
	}

	.mask {
		height: auto;
	}

	.slider {
		display: block;
		height: auto;
	}

	.div-block {
		margin-top: -16px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slideshowsection {
		margin-top: 151px;
	}

	.div-block-2 {
		width: 95%;
		margin-right: 0px;
	}

	.div-block-2.bikerider {
		width: 95%;
	}

	.div-block-3 {
		display: block;
		width: 95%;
		margin-top: -32px;
	}

	.left-arrow {
		left: 0%;
		top: auto;
		right: auto;
		bottom: 0%;
		width: 50px;
		height: 30px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.right-arrow {
		position: absolute;
		left: auto;
		top: auto;
		right: 0%;
		bottom: 0%;
		width: 60px;
		height: 30px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.vintagearrow {
		width: 60px;
	}

	.multitudeofuses {
		z-index: 1;
	}

	.dottedline {
		width: 20%;
	}

	.centerfluer {
		width: 60%;
		background-position: 50% 62%;
	}

	.heading {
		margin-top: 0px;
		font-size: 20px;
	}

	.heading-2.brownheading {
		width: 175px;
	}

	.usesimageholder.creamjar {
		width: 95%;
		height: auto;
		text-align: center;
		display: block;
	}

	.downloadgraphic {
		width: 93%;
	}

	.distressedbuttonlarge {
		width: 75%;
	}

	.successblurb {
		width: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.text-block {
		text-align: center;
	}

	.grip-logo-link {
		float: none;
	}

	.footer-list-link:hover {
		border-left-color: transparent;
	}

	.list-link-div {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-bottom: 1.5rem;
		padding-bottom: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid hsla(0, 0%, 54.5%, 0.25);
	}

	.list-link-wrapper {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-copyright {
		margin-bottom: 0.5rem;
		text-align: center;
	}

	.column-3 {
		margin-bottom: 0.5rem;
	}

	.web-development-by {
		right: 22%;
	}

	.column-2 {
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.text-block-3 {
		text-align: center;
	}

	.footer-signoff-links.last {
		margin-right: 0rem;
	}

	.makinghineyshappy {
		width: 95%;
	}

	.main-content-wrap.with-left-sidebar {
		padding-top: 1rem;
	}

	.followusholder {
		width: 100%;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
table.tablesaw th,
table.tablesaw td {
	overflow-wrap: anywhere;
}

table.styled.tablesaw th,
table.styled.tablesaw td {
	border-left: 0;
	border-top: 0;
}


@media (max-width: 50em) {
	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 0;
	}
}

@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}

	.tablesaw.styled td .tablesaw-cell-label {
		background-color: #ce5b29;
		color: #fff;
		padding: 9px 10px;
		width: calc(100% + 40px);
		margin-left: -10px;
		margin-top: -6px;
		margin-bottom: 5px;
	}
}

.tablesaw-stack td .tablesaw-cell-label,
.tablesaw-stack th .tablesaw-cell-label {
	width: 100%;
}

.tablesaw-cell-content {
	width: 100%;
	max-width: none;
}


/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printHeader,
#printFooter {
	display: none;
}

/****************** Header ***********************/
/*************************************************/
.desktop-navigation ul {
	margin: 0px;
	padding: 0px;
	margin: 0px;
	list-style-type: none;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.desktop-navigation ul li {
	margin: 0px;
	padding: 0px;
	display: inline;
}

.desktop-navigation ul li a.active {
	border-top: 1px none red;
	background-color: rgba(0, 0, 0, 0.1);
	box-shadow: 0 -2px 0 0 #ce5b29;
}

/****************** Mobile navigation ****************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mm-wrapper_opened .headerbackground {
	position: static;
}

.mobile-navigation-menu {
	background-color: #ce5b29;
}

.mm-panels > .mm-panel > .mm-listview {
	margin-left: 0px;
	margin-right: 0px;
}

.mobile-navigation-menu li {
	background-image: url("/20170713122722/assets/images/Dots-White.svg");
	background-position: 0% 100%;
	background-size: auto 2px;
	background-repeat: repeat-x;
}


.mobile-navigation-menu li.active .nav-link {
	background-color: rgba(0, 0, 0, 0.1);
	box-shadow: 0 -2px 0 0 #ce5b29;
}

.mobile-navigation-menu li a.nav-link {
	-webkit-transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: #fff;
	font-size: 13px;
	text-transform: uppercase;
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: #fff;
}

.mm-listitem .mm-btn_next:before {
	border-color: rgba(255, 255, 255, 0.4);
}

.mm-navbar {
	height: 45px;
	background-image: url("/20170713122722/assets/images/Dots-White.svg");
	background-position: 0% 100%;
	background-size: auto 2px;
	background-repeat: repeat-x;
	border-color: transparent;
	margin-right: 20px;
	margin-left: 20px;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: #fff;
	font-size: 13px;
	text-transform: uppercase;
	text-align: left;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #fff;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -15px;
}

.mm-navbar > * {
	padding-top: 13px;
}

.mm-navbar__btn:first-child {
	left: -5px;
	top: 2px;
}

/********************** Homepage ********************/
/****************************************************/
.heading-2 {
	margin-top: 20px;
}

.heading-2 p {
	font-family: inherit;
	color: inherit;
	line-height: inherit;
	font-size: inherit;
	letter-spacing: inherit;
	margin-top: 3px;
	margin-bottom: 8px;
	text-transform: uppercase;
	font-weight: bold;
}

.heading-2 h1,
.heading-2 h2,
.heading-2 h3,
.heading-2 h4,
.heading-2 h5,
.heading-2 h6,
.heading-2 li,
.heading-2 span,
.heading-2 td {
	font-family: inherit;
	color: inherit;
	font-weight: inherit;
	text-transform: uppercase;
	font-weight: bold;
}

.heading-2 ul {
	list-style-type: none;
}

.distressedbuttonlarge.learnmorebrown:hover {
	color: #fff;
}

.usesimageholder {
	margin-bottom: 5px;
}

.heading {
	line-height: 44px;
}

.heading a,
.heading a:hover {
	text-decoration: none;
	color: inherit;
}

.heading.text p {
	font-family: inherit;
	color: inherit;
	line-height: inherit;
	font-size: inherit;
	letter-spacing: inherit;
	font-weight: inherit;
	text-transform: uppercase;
	margin: 0px 0px 5px 0px;
}

.heading.text p:last-child {
	margin: 0px;
}

.heading.text h1,
.heading.text h2,
.heading.text h3,
.heading.text h4,
.heading.text h5,
.heading.text h6,
.heading.text li,
.heading.text span,
.heading.text td {
	font-family: inherit;
	color: inherit;
	font-weight: inherit;
	text-transform: uppercase;
	font-weight: bold;
}

.heading.text ul {
	list-style-type: none;
}


.distressedbuttonlarge,
.distressedbuttonlarge:hover {
	color: #fff;
}

.multitudeofuses {
	min-height: 200px;
	clear: both;
}

.div-block-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 15px;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.side-frill-left {
	width: 30px;
	height: 60px;
	margin-right: 10px;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.side-frill-left.with-background {
	background-image: url("/20170713122722/assets/images/Side-Frills.svg");
}

.div-block-5 {
	text-align: center;
	font-family: Poppins, sans-serif;
	color: #ce5b29;
	font-weight: 800;
	text-align: center;
	width: 150px;
	text-transform: uppercase;
}

.side-frill-right {
	width: 30px;
	height: 60px;
	margin-left: 10px;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.side-frill-right.with-background {
	background-image: url("/20170713122722/assets/images/Side-Frills.svg");
}

.div-block-5 a {
	font-family: Poppins, sans-serif;
	color: #ce5b29;
	font-weight: 800;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.div-block-5 a:hover {
	color: #008083;
}

.div-block-5 p,
.div-block-5 li,
.div-block-5 span {
	font-family: Poppins, sans-serif;
	color: #ce5b29;
	font-weight: 800;
	text-align: center;
	text-transform: uppercase;
}

/******************** Slideshow *********************/
/****************************************************/
.slideshow-caption {
	color: #47413c;
	font-size: 18px;
	line-height: 22px;
	font-family: Poppins, sans-serif;
	font-weight: 400;
	text-align: center;
}

.storyblurb {
	margin-bottom: 0px;
	margin-top: 5px;
}

.slideshow-caption p {
	margin-top: 0px;
	margin-bottom: 5px;
}

.slideshow-caption p:last-child {
	margin: 0px;
}

.slideshow-dotted-text {
	font-family: Poppins, sans-serif;
	color: #47413c;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 20px;
	background-image: url("/local/public/shared/assets/images/Dots2.svg");
	background-position: 50% 0%;
	background-size: 30px;
	background-repeat: repeat-x;
	padding-top: 8px;
	display: inline-block;
	margin-top: 0.5rem;
	padding-right: 38px;
	padding-left: 38px;
}

.slide .more-btn {
	position: relative;
	width: 100%;
	height: 100%;
	text-decoration: none;
}

.mask,
.slide {
	height: auto;
}

.slide {
	background-image: url("/20170713122722/assets/images/BG-Texture.jpg");
	background-position: 0px 0px;
}

.slideshowsection {
	min-height: 200px;
}

.without-caption img {
	max-height: 700px;
}

@media (max-width: 767px) {
	.slide {
		min-height: 300px;
	}
}

@media (max-width: 479px) {
	.slide {
		min-height: 450px;
	}
}

/******************** Inside page ********************/
/*****************************************************/
img {
	height: auto;
	max-width: 100%;
}

td img {
	max-width: none;
}

.breadcrumbs-list li:last-child .breadcrumb-divider {
	display: none;
}

.left-nav-link-level-1a.active,
.left-nav-link-level-1b.active,
.left-nav-link-level-1.active {
	color: #ce5b29;
	background-color: transparent;
}

.left-nav-list {
	padding-top: 0px;
}

.left-nav-section-title-link {
	margin: 0px;
	padding-bottom: 0px;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.left-nav-section-title-link a,
.left-nav-section-title-link a:hover {
	text-decoration: none;
	background-color: #ce5b29;
	display: inline-block;
	width: 100%;
	padding: 0.5rem 1rem;
	color: #fff;
}

.inside-page-section.only-right .main-content-wrap.between-left-right {
	width: 79%;
	padding-top: 0px;
}

.inside-page-section.only-left .main-content-wrap.between-left-right {
	width: 79%;
}

.inside-page-section.no-columns .main-content-wrap.between-left-right {
	width: 100%;
}

.inside-page-section.no-columns .main-content-wrap.between-left-right {
	padding-top: 0px;
}

@media (max-width: 991px) {
	.inside-page-section.both-columns .container-2.inside-page-container {
		display: block;
	}

	.inside-page-section.no-columns .main-content-wrap.between-left-right,
	.inside-page-section.only-right .main-content-wrap.between-left-right {
		padding-top: 2rem;
	}

	.inside-page-section.only-left .main-content-wrap.between-left-right {
		display: block;
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.inside-page-section.only-right .container-2.inside-page-container {
		display: block;
	}

	.inside-page-section.only-right .main-content-wrap.between-left-right {
		width: 100%;
	}

	.right-nav-div {
		padding-top: 1.5rem;
	}

	.right-nav-col {
		display: block;
		width: 100%;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}

@media (max-width: 767px) {
	.inside-page-section.only-left .main-content-wrap.between-left-right {
		padding-top: 1rem;
	}


	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0px;
		margin-right: 0px;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-nav-col .box {
	margin-bottom: 0px;
	padding-top: 4rem;
	padding-bottom: 0px;
	border-right: 6px none #edb761;
	display: inline-block;
	width: 100%;
	margin-top: 1rem;
	padding-top: 0px;
	font-family: Poppins, sans-serif;
	font-size: 0.9em;
}

.right-nav-col .box h4,
.right-nav-col .box h3 {
	margin-top: 0px;
	margin-bottom: 12px;
	padding-left: 0px;
	background-color: transparent;
	font-family: 'Libre Baskerville', sans-serif;
	color: #ce5b29;
	font-size: 1.25rem;
	line-height: 1.775rem;
	font-weight: 600;
	text-transform: none;
}

.right-nav-col a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Poppins, sans-serif;
	color: #47413c;
	font-size: 0.9em;
	text-decoration: none;
}

.right-nav-col a:hover {
	color: #ce5b29;
}

.right-nav-col .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-nav-col .box .contentbox_item_image {
	width: 24px;
}

.right-nav-col .box.documents td:first-child {
	padding-right: 7px;
	width: 24px;
}

.right-nav-col .box.documents table {
	width: 100%;
}

.right-nav-col .box.documents table td {
	padding: 12px 2px;
	border-bottom: 1px none #47413c;
	background-image: url("/20170713122722/assets/images/Dots2.svg");
	background-position: 0% 100%;
	background-size: 20px;
	background-repeat: repeat-x;
}

.right-nav-col .box.documents table:first-child tr td {
	padding-top: 0px;
}

.right-nav-col .box.tools td:first-child img {
	margin-right: 5px;
}

.right-nav-col .box.links p {
	padding: 12px 2px;
	border-bottom: 1px none #47413c;
	background-image: url("/20170713122722/assets/images/Dots2.svg");
	background-position: 0% 100%;
	background-size: 20px;
	background-repeat: repeat-x;
	margin: 0px;
}

.right-nav-col .box.links p:first-child {
	padding-top: 0px;
}

/********************** Footer ***********************/
/*****************************************************/
.footer-links {
	margin: 0px;
	padding: 0px;
	text-align: center;
	list-style-type: none;
}

.footer-links li {
	margin: 0px;
	padding: 0px;
}

.footer-links-div .text-block-3 a {
	color: #47413c;
	text-decoration: none;
}

.footer-list-link.active {
	color: #ce5b29;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
	line-height: 11px;
}

.footer-links-div .list-link-div ul {
	margin: 0;
	padding: 0;
	text-align: center;
	list-style-type: none;
}


.footer-links-div .list-link-div li {
	margin: 0;
	padding: 0;
}

.footer-links-div .list-link-div li a {
	margin-bottom: 0.35rem;
	padding-right: 0.25rem;
	padding-bottom: 0;
	padding-left: 0.35rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px none hsla(0, 0%, 54.5%, 0.25);
	border-left: 3px solid transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #47413c;
	line-height: 1.3rem;
	text-align: center;
	text-decoration: none;
	display: inline-block;
}

.footer-links-div .list-link-div li a:hover {
	border-bottom-color: transparent;
	border-left-color: transparent;
	color: #ce5b29;
}

@media (max-width: 991px) {
	.footer-links-div .list-link-div li a {
		text-align: center;
	}
}

@media (max-width: 767px) {
	.footer-links-div .list-link-div li a {
		text-align: center;
	}
}

@media (max-width: 479px) {
	.footer-links-div .list-link-div li a:hover {
		border-left-color: transparent;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: Poppins, sans-serif;
	color: #008083;
	font-weight: 700;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: #ce5b29;
}

a {
	transition: all 200ms ease;
	color: #008083;
}

a:hover {
	color: #ce5b29;
}

p {
	font-family: Poppins, sans-serif;
}

/* buttons */
.button,
.primary,
button[type="submit"],
.cms_form_button.primary,
.form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.button {
	display: inline-block;
	padding: 9px 15px !important;
	background-color: #3898ec;
	color: white;
	border: 0;
	line-height: inherit;
	text-decoration: none;
	cursor: pointer;
	border-radius: 0;
	background-color: transparent;
	background-image: url("/20170713122722/assets/images/Button-BG.svg");
	background-size: cover;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	font-family: Poppins, sans-serif;
	font-size: 20px;
	font-weight: 800;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 1px;
	min-width: 210px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.button:hover,
.primary:hover,
button[type="submit"]:hover,
.cms_form_button.primary:hover,
.form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.button:hover {
	text-decoration: underline;
	color: #fff;
	cursor: pointer;
}

.secondary,
button[type="submit"].secondary,
.cms_form_button.secondary,
.form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	background-color: #008083;
	background-image: none;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 1px;
	text-decoration: none;
	text-align: center;
	border: 0px;
	cursor: pointer;
	color: #fff;
	font-family: Poppins, sans-serif;
	display: inline;
	text-transform: uppercase;
	padding: 12px 15px 10px 15px !important;
	line-height: 16px;
	min-width: 170px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.secondary:hover,
button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
.form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	background-color: #ce5b29;
	color: #fff;
	cursor: pointer;
}

.tertiary,
button[type="submit"].tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	background-color: #008083;
	background-image: none;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 1px;
	text-decoration: none;
	text-align: center;
	border: 0px;
	cursor: pointer;
	color: #fff;
	font-family: Poppins, sans-serif;
	display: inline;
	text-transform: uppercase;
	padding: 12px 15px 10px 15px !important;
	line-height: 16px;
	min-width: 170px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.tertiary:hover,
button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	background-color: #ce5b29;
	color: #fff;
	cursor: pointer;
}

/* messages */
#message.success,
#polls .session_message.success {
	background-color: rgba(17, 185, 30, 0.25);
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #333;
}

#message.error,
#polls .session_message.error {
	background-color: rgba(253, 54, 54, 0.15);
	text-align: left;
	padding: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	color: #333;
}

#message.success *,
#message.error *,
#polls .session_message.success p,
#polls .session_message.error p {
	color: #333;
	font-size: 1.0625rem;
	line-height: 1.5em;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #ce5b29;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: darkgray;
	color: black;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
}

table.styled th a:hover {
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: lightgray;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
select,
textarea {
	max-width: 100%;
	padding: 0.39rem 0.75rem;
	border: 1px solid #d6d6d6;
	border-radius: 0px;
	font-size: 0.875rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
}

input[type="search"] {
	margin-bottom: 0px;
}

select {
	padding: 0.45rem 0.75rem;
}

textarea {
	resize: vertical;
}

@media (max-width: 991px) {
	input[type="text"],
	input[type="tel"],
	input[type="password"],
	input[type="email"],
	input[type="search"],
	select,
	textarea {
		width: 100%;
	}


	input[type="search"] {
		margin-bottom: 10px;
	}
}

/*blockquotes*/
blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1.25rem;
	border-left: 5px solid #ce5b29;
	font-family: 'Libre Baskerville', sans-serif;
	font-size: 1.125rem;
	line-height: 1.5rem;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	font-family: inherit;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
	color: #333 !important;
}

.pagination-wrapper span {
	color: #333 !important;
	font-size: inherit;
}


/********************** Modules ***********************/
/*****************************************************/
/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #aaa;
	margin: 20px 0;
	padding-bottom: 4px;
	color: #002147;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider,
#events_module.cms_entity .cms_divider {
	border-top: 1px solid #aaa;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 15px;
}

#faqs_module .cms_list_item ul li,
#faqs_module.cms_list .cms_list_subitem {
	margin-bottom: 10px;
}

/* news */
#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	border-top: 1px solid #aaa;
	margin-bottom: 1rem;
	margin-top: 1.5rem;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* photo albums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	border-top: 1px solid #aaa;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}


/* posts */
.posts-collection .posts-node {
	border-top: 1px solid #aaa;
	margin-top: 1.5rem;
	padding-top: 1.2rem;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background: #008083;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid #aaa;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.posts-node .required_information {
	color: #ee0000;
	font-weight: 400;
}


/* sidebar posts */
.right-nav-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-nav-col .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-nav-col .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-nav-col .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-nav-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.right-nav-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 37px;
}

.right-nav-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 8px 11px 7px !important;
	min-width: auto;
}

/* products */
#products_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#products_module.cms_entity .cms_content .image {
	margin-right: 15px;
	margin-left: 0px;
}

#products_module.responsive.cms_entity .related-items.slider .related-item {
	border: 2.5px solid #f6f6f6;
	border-radius: 4px;
	background: none;
	box-sizing: content-box;
}

@media (max-width: 991px) {
	#products_module.cms_list .cms_list_item.left,
	#products_module.cms_list .cms_list_item.right {
		width: 100%;
		display: block;
		padding-left: 0px;
		padding-right: 0px;
	}

	#products_module.cms_list .cms_list_item .tile_description .image {
		float: none;
		display: block;
		padding-left: 0px;
	}

	#products_module.cms_list .cms_list_item .cms_title {
		padding: 0px;
	}

	#products_module.cms_list .cms_list_item.left tr td,
	#products_module.cms_list .cms_list_item.right tr td {
		border-bottom: 1px solid #aaa;
		padding-top: 1.2rem !important;
		padding-bottom: 1.2rem !important;
	}

	#products_module.cms_list .cms_item_divider {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		background: none !important;
	}
}

@media (max-width: 767px) {
	#products_module.cms_entity .cms_content .image {
		display: inline-block;
		float: none;
		margin: 0px 0px 10px 0px;
	}
}

/* resources */

/* rss feed */
#feeds_module.cms_list .cms_title h3,
#feeds_module.cms_list .cms_date h4 {
	margin-top: 0px;
}

#feeds_module.cms_list .abstract {
	padding: 0px;
}

#feeds_module.cms_list .cms_item_divider {
	border-top: 1px solid #aaa;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}


/* submission forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 3px;
	font-size: 0.9rem;
	color: #000;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	color: #000;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .cms_required em,
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .formSection,
.module-forms .formSection {
	background-color: #aaa;
	color: #000;
}

#submissionforms_module.cms_form .cms_steps_wrapper .active,
#submissionforms_module.cms_form .cms_steps_wrapper .last_active {
	background-color: #aaa;
	font-style: italic;
}

#submissionforms_module.cms_form .cms_steps_wrapper .active:before {
	border-left: 13px solid #aaa;
}

@media (max-width: 991px) {
	#submissionforms_module.cms_form .cms_field input.hasDatepicker[size="60"][type="text"] {
		width: 100% !important;
	}
}


/* success stories */
#stories_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#stories_module.cms_list .cms_list_item .image {
	max-width: 200px;
}

#stories_module.cms_list .cms_item_divider,
#stories_module.cms_entity .cms_item_divider {
	border-top: 1px solid #aaa;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}


@media (max-width: 991px) {
	#stories_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		display: block;
		float: none;
	}
}

/* testimonials */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_author_name {
	font-weight: 500;
	font-size: 0.9rem;
}

#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	border-top: 1px solid #aaa;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

/* videos */
#video_module.cms_list .cms_list_item,
#video_module.cms_list_carousel .category-container {
	border-bottom: 1px solid #aaa;
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
}


/********************* eCommerce *********************/
/*****************************************************/
#ecommerceLinks {
	position: fixed;
	bottom: 0;
	z-index: 10000;
	top: 91px;
	right: 4px;
	height: 50px;
}

#ecommerceLinks a {
	padding: 12px;
	text-decoration: none;
}

#ecommerceLinks a:hover {
	color: #fff;
	border-top: 1px none red;
	background-color: rgba(0, 0, 0, 0.1);
	box-shadow: 0 -2px 0 0 #ce5b29;
	text-decoration: none;
}

.ecommerceLinkText {
	display: inline;
	font-weight: 700;
	font-size: 0.75rem;
	text-align: right;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 0.75rem;
	color: #fff;
	margin-top: 3px;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

.ecommerceLinkImg {
	width: 1.2rem;
	height: 1.2rem;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}

/* listing */
#products_module.responsive.cms_list .items.list-view .item-wrapper .item {
	margin: 0px;
}

#products_module.responsive.cms_list .pagination-wrapper {
	border-top: 1px solid #aaa;
	border-bottom: 1px solid #aaa;
	padding-bottom: 7px;
	padding-top: 7px;
	margin-bottom: 20px;
}

#products_module.responsive.cms_list .pagination-wrapper .fly-out-menu-container .fly-out-menu-button {
	border: 1px solid #ccc;
}

#products_module.responsive.cms_list .items.list-view .item-wrapper {
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
	border-bottom: 1px solid #aaa;
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-price {
	font-weight: bold;
}

.products_module .item-sale-price,
#products_module.responsive .item-sale-price {
	color: #d10000 !important;
	font-weight: bold;
}

#products_module.responsive .items .item-wrapper .item .item-image {
	border: 1px solid #aaa;
}

@media (max-width: 991px) {
	#ecommerceLinks {
		position: fixed;
		bottom: 0;
		z-index: 10000;
		top: 91px;
		right: 75px;
	}

	#ecommerceLinks a {
		padding: 12px 8px;
	}

	#products_module.responsive.cms_list .items.list-view .item-wrapper .item .item-description {
		margin-left: 0;
		width: 100%;
	}

	#products_module.responsive.cms_list .items.list-view .item-wrapper .item .item-image {
		float: none;
		margin-bottom: 10px;
	}

	#products_module.responsive.cms_list .items.list-view .item-wrapper {
		margin-bottom: 1.5rem;
		padding-bottom: 0.8rem;
	}
}

/* details view */
#products_module.responsive.cms_entity .mini-slide-image-outer-wrapper.selected {
	border: 1px solid #ce5b29;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	padding: 15px;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info .product-prices {
	font-weight: bold;
}

#products_module.responsive.cms_entity .add_to_cart_form .contentbox_item br {
	display: none;
}

#products_module.responsive.cms_entity .add_to_cart_form .quantity_row .product-quantity-label {
	width: 85px;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info .add_to_cart_form .denomination-validation-message,
#products_module.responsive.cms_entity .add_to_cart_form .minimum_quantity_label,
#products_module.responsive.cms_entity .add_to_cart_form .maximum_quantity_label {
	font-weight: bold;
	color: #d10000;
}

#products_module.responsive.cms_entity .availability_box .available {
	font-weight: bold;
	color: #5b625e;
	display: block;
}

#products_module.responsive.cms_entity .add_to_cart_form input[type="text"].hasDatepicker {
	width: calc(100% - 30px);
}

#products_module.responsive.cms_entity .add_to_cart_form .attribute-type-date img {
	margin-left: 5px;
	margin-top: -7px;
}

#products_module.responsive.cms_entity .related-items .related-items-title,
#products_module.responsive.cms_entity .recently-viewed-items .recently-viewed-items-title {
	margin: 25px 0px 0px 0px;
	font-size: 1.5rem;
	font-weight: 600;
	display: inline-block;
}

#products_module.responsive.cms_entity .contentbox_item .accepted-formats {
	word-break: break-all;
	line-height: 1rem;
}

#products_module.responsive.cms_entity .related-items-wrapper .item-wrapper .item-price {
	font-weight: 600;
}

#products_module.responsive.cms_entity .tab-item-content .product-review .product-review-author {
	font-size: 0.875rem;
	font-weight: 600;
}

#products_module.responsive.cms_entity .tab-item-content .product-review {
	border-bottom: 1px solid hsla(0, 0%, 66%, 0.35);
	margin-bottom: 1.5rem;
	margin-top: 0;
	padding-bottom: 1.5rem;
	padding-top: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-review:first-of-type {
	margin-top: 1rem;
}

#products_module.responsive.cms_entity .tab-item-content .product-review:last-of-type {
	border-bottom: 0 none;
	padding-bottom: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-have-a-question {
	padding-top: 0;
	border-top: 0 none;
	font-weight: normal;
}

#products_module.responsive.cms_entity .tab-item-content .product-question p {
	font-weight: normal;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer {
	margin: 5px 0 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer p:last-of-type {
	margin-bottom: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-questions {
	margin: 0 0 1.5rem 0;
	padding: 0 0 1.5rem 0;
	border-bottom: 1px solid hsla(0, 0%, 66%, 0.35);
}

#products_module.responsive.cms_entity .tab-item-content .product-questions:last-of-type {
	padding-bottom: 0;
	border-bottom: 0 none;
}

#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: normal;
}

#products_module.responsive.cms_entity .product-attribute-file-upload input {
	font-size: 0.8rem;
}

#products_module.responsive.cms_entity .product-details-wrapper .product-details-info-wrapper .product-details-info {
	background-color: transparent;
	border: 2.5px solid #f6f6f6;
}

#products_module.responsive.cms_form .required-fields-information em {
	color: #ee0000;
}

#products_module.responsive.cms_form .ui-form-label {
	height: auto;
}


#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item-content {
	border-left: 2.5px solid #f6f6f6;
	border-right: 2.5px solid #f6f6f6;
	border-bottom: 2.5px solid #f6f6f6;
	background-color: transparent;
}

@media screen and (min-width: 721px) {
	#products_module.responsive.cms_entity .product-details-tabs .tab-item-content {
		border-top: 2.5px solid #f6f6f6;
		margin-left: 1.9px;
	}
}


@media (max-width: 991px) {
	#products_module.responsive.cms_form .ui-form-field {
		width: 100% !important;
	}
}

@media (max-width: 720px) and (min-width: 481px) {
	#products_module.responsive.cms_entity .add_to_cart_form .cart-estimate-button {
		margin-right: 10px;
		margin-left: 10px;
	}

	#products_module.responsive .add_to_cart_form .cart-estimate-button {
		width: 51%;
	}
}

/* cart */
.cart-grid .s-row.s-odd,
#order-pad .s-row.s-odd,
#s-cart .s-row.s-odd,
#s-wishlist .s-row.s-odd,
#s-favorites .s-row.s-odd,
.cart-grid .s-row.s-header-row,
#order-pad .s-row.s-header-row,
#order-review .s-row.s-header-row,
#s-cart .s-row.s-header-row,
#s-wishlist .s-row.s-header-row,
#s-favorites .s-row.s-header-row,
#s-checkout .s-row.s-odd {
	border-bottom: 1px solid #aaa;
}

#s-checkout #s-totals,
#s-order-confirmation #s-totals {
	border-top: 1px solid #aaa;
}

#s-checkout fieldset {
	border: 1px solid #aaa;
}

#products_module.responsive.cms_form .required-fields-information em,
#products_module.responsive.cms_form label em,
#products_module.responsive.cms_form .ui-form-label em,
#s-customer label em,
#s-checkout label em,
#s-checkout span em {
	color: #ee0000;
}


#s-checkout .ui-form-buttons.ui-form-field-shipping,
.ui-form-buttons.ui-form-field-payment,
#s-checkout .ui-form-buttons.ui-form-field-place-order {
	padding: 0 !important;
}

#s-order-confirmation h2,
#s-order-confirmation h3 {
	margin-top: 0px;
}

#s-order-confirmation .underline-section {
	border-top: 1px solid #aaa;
}

#s-checkout fieldset .ui-form-legend .ui-form-legend-text {
	font-weight: 600;
	font-size: 1.4rem;
}

#s-customer #s-sign-in .ui-form-label {
	height: auto;
}

@media (max-width: 991px) {
	#s-checkout .ui-form-text-phone-extension {
		margin-left: 0px;
		width: 100%;
	}

	#s-order-confirmation .ui-form-shipping-address-parent .columns,
	#s-order-confirmation .ui-data-payment .ui-data-payments,
	#s-order-confirmation .ui-data-payment .ui-data-addresses {
		width: 100%;
		float: none;
	}
}

@media (max-width: 767px) {
	#s-cart .s-row .s-quantity-field,
	#s-wishlist .s-row .s-quantity-field,
	#s-favorites .s-row .s-quantity-field {
		height: 40px;
	}

	#s-customer #s-sign-in .ui-form-input {
		width: 100%;
	}
}

/**************** Content Boxes ******************/
/*************************************************/
/* events */
.slideshowsection #events_module.homepage_contentbox {
	margin-left: 20px;
}

#events_module.homepage_contentbox,
#events_module.homepage_contentbox p,
#events_module.homepage_contentbox a,
#events_module.homepage_contentbox td {
	text-align: left;
	text-transform: none;
	font-size: 1rem;
	line-height: 1.2rem;
	margin-bottom: 20px;
}

#events_module.homepage_contentbox .event_list {
	margin-bottom: 15px;
}

#events_module.homepage_contentbox .event_list .date {
	width: 100px;
}

#events_module.homepage_contentbox .event_list .title {
	width: calc(100% - 100px);
}


/* news */
.slideshowsection #news_module.homepage_contentbox {
	margin-left: 20px;
}

#news_module.homepage_contentbox {
	max-width: 600px;
}

#news_module.homepage_contentbox,
#news_module.homepage_contentbox p,
#news_module.homepage_contentbox a,
#news_module.homepage_contentbox td {
	text-align: left;
	text-transform: none;
	font-size: 1rem;
	line-height: 1.2rem;
	margin-bottom: 20px;
}

#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 10px;
}

#news_module.homepage_contentbox .cms_title h3 {
	margin: 0px;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 0.9rem;
	margin: 0px;
	line-height: 1rem;
	font-weight: normal;
}

/* posts */
.slideshowsection.homebox .collection.posts-collection {
	margin-left: 20px;
}

.homebox .collection.posts-collection {
	max-width: 600px;
	font-size: 1rem;
	line-height: 1.2rem;
	margin-bottom: 20px;
	text-align: left;
	text-transform: none;
}

.homebox .collection.posts-collectionp,
.homebox .collection.posts-collection p,
.homebox .collection.posts-collection a,
.homebox .collection.posts-collection td {
	text-transform: none;
}

.posts-collection.homepage-contentbox {
	font-size: 1rem;
	line-height: 1.2rem;
	margin-bottom: 20px;
	text-align: left;
	text-transform: none;
}

.posts-collection.homepage-contentbox.article-items .article-image {
	width: auto;
}

.homebox .collection.posts-collection h3 {
	font-size: 1.2rem;
}

/* rss */
.slideshowsection.homebox #feeds_module.homepage_contentbox {
	margin-left: 20px;
}

#feeds_module.homepage_contentbox {
	font-size: 1rem;
	line-height: 1.2rem;
	margin-bottom: 20px;
	text-align: left;
	text-transform: none;
}


/* testimonials */
.slideshowsection.homebox #testimonials_module_contentbox {
	margin-left: 20px;
}

#testimonials_module_contentbox {
	font-size: 1rem;
	line-height: 1.2rem;
	margin-bottom: 20px;
	text-align: left;
	text-transform: none;
	max-width: 600px;
}

#testimonials_module_contentbox p,
#testimonials_module_contentbox a,
#testimonials_module_contentbox td,
#testimonials_module_contentbox span {
	font-size: 1rem;
	line-height: 1.2rem;
	text-align: left;
	text-transform: none;
}

#testimonials_module_contentbox .description-box,
#testimonials_module_contentbox .description-box span {
	margin-bottom: 10px;
	font-weight: normal;
}

#testimonials_module_contentbox .author-name,
#testimonials_module_contentbox .company {
	font-weight: bold;
}

#testimonials_module_contentbox.contentbox_item.withThumbnail .testimonials-item .image-box img {
	margin-bottom: 15px;
}

/* videos */
.homepage-featured-video {
	margin-bottom: 20px;
}

.homepage-featured-video iframe {
	max-width: 100%;
}

.homepage-featured-video .cbTitle {
	font-size: 1.3rem;
	font-weight: bold;
	margin-bottom: 3px;
}

/* submission forms */
.slideshowsection.homebox #submissionforms_module.homepage_contentbox {
	margin-left: 20px;
}

#submissionforms_module.homepage_contentbox,
#submissionforms_module.homepage_contentbox td,
#submissionforms_module.homepage_contentbox span {
	font-size: 1rem;
	line-height: 1.2rem;
	text-align: left;
	text-transform: none;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/* eCommerce */
#products_module.featured_list .cms_title {
	text-align: center;
}

#products_module.featured_list .cms_title h3 a {
	text-align: center;
	font-family: 'Libre Baskerville', sans-serif;
	font-size: 16px;
	letter-spacing: 1px;
	text-decoration: none;
	font-weight: bold;
	color: #47413c;
	text-transform: uppercase;
}

#products_module.homepage_contentbox.displaymode_category_list .cms_image {
	margin-top: 10px;
	margin-bottom: 15px;
	text-align: center;
}

#products_module.homepage_contentbox.displaymode_category_list .links a {
	text-align: center;
	font-family: 'Libre Baskerville', sans-serif;
	font-size: 16px;
	letter-spacing: 1px;
	text-decoration: none;
	font-weight: bold;
	color: #47413c;
	text-transform: uppercase;
}


/**************** UI form tags ******************/
/*************************************************/
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-form-legend {
	background: none;
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

@media (max-width: 991px) {
	#account_module .ui-form-fields,
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label,
	#account_module .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input,
	#account_module .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	#account_module .ui-form-input input[type="text"],
	#account_module .ui-form-input input[type="password"],
	#account_module .ui-form-input input[type="email"],
	#account_module .ui-form-input input[type="file"],
	#account_module .ui-form-input select,
	#account_module .ui-form-input textarea {
		width: 100% !important;
		max-width: 100%;
	}

	.ui-form-select > select,
	.ui-form-textarea > textarea,
	.ui-form-text > input[ type="password" ],
	.ui-form-text > input[ type="text" ] {
		width: 100%;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}
